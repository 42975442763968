/* You can add global styles to this file, and also import other style files */

/* Importing fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');

/* Importing typography*/
@import './assets/stylesheets/typography.scss';

/* Importing helper classes */
@import './assets/stylesheets/helpers.scss';

/* Importing authentication module styles */
@import './assets/stylesheets/auth.scss';

/* All other scss imports will go here */
@import './assets/stylesheets/modal.scss';


// For hiding the browser scrollbar 
// body::-webkit-scrollbar {
//     display: none;
// }

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Global styles */
body {
    // background-color: #e5e5e5 !important;
    // #E9F5F0 !important
    background-color: #E9F5F0 !important;
}

// h1, h2, h3, h4, h5, h6, p, a {

// }

select:focus {
    box-shadow: none !important;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.error-block {
    font-size: 13px;
    color: #dc3545;
    text-align: start;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
}

.filter-wrapper {
    background-color: #f5f5f5;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #e3e3e3;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

    .container {
        .row {
            label {
                font-size: 14px;
            }
        }
    }
}

.graph-filter-btn {
    font-size: 10px !important;
    margin-right: 5px;
}

.form-title {
    border-bottom: 1px solid #67675f;

    h6 {
        color: #67675f;
    }
}

.form-control:focus {
    box-shadow: none !important;
}

.input-group {
    button.btn-date {
        color: #fff !important;
        background-color: #212b55 !important;
    }
}

form {
    label {
        font-size: 14px !important;
    }

    label.required{
        &:after {
            content: "*";
            color: #c64326;
        }
    }
    input.required-field::placeholder {
        &:after {
            content: "*";
        }
    }
}   

.btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.theme-green {
    .bs-datepicker-head {
        background-color: #212b55 !important;
    }

    .bs-datepicker-body {
        table {
            td.week {
                span {
                    color: #212b55 !important;
                }
            }
        }
    }
}

.btn-group {
    font-family: 'Open Sans', sans-serif !important;
    .btn {
        height: 34px;
        padding: 5px 10px;
        font-size: 14px !important;
        color: #212b55;
        border-color: #212b55;
    }
    .btn:hover {
        background-color: #212b55 !important;
        border-color: #212b55 !important;
    }
    .btn:active {
        background-color: #212b55 !important;
        border-color: #212b55 !important;
    }
    .btn.active {
        background-color: #212b55 !important;
        border-color: #212b55 !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px white inset !important;
}

// .theme-green {
// 	.bs-datepicker-body {
// 		table {
// 			td.active-week {
// 				span {
// 					&:hover {
// 						cursor: pointer;
// 						background-color: #883cae !important;
// 					}
// 				}
// 			}
// 			td.week {
// 				span {
// 					color: #883cae !important;
// 				}
// 			}
// 			td {
// 				span.selected {
// 					background-color: #883cae !important;
// 				}
// 				span[class*="select-"] {
// 					&:after {
// 						background-color: #883cae !important;
// 					}
// 				}
// 			}
// 			td.selected {
// 				span {
// 					background-color: #883cae !important;
// 				}
// 			}
// 			td[class*="select-"] {
// 				span {
// 					&:after {
// 						background-color: #883cae !important;
// 					}
// 				}
// 			}
// 		}
// 	}
// }



.sitenav-wrapper {
    //   padding: 20px;

    .progress2 {
        li {
            display: inline-block;
            width: 50%;
            text-align: center;
            position: relative;

            button {
                background: transparent;
                border: none;
                position: relative;
                z-index: 1;

                &:focus {
                    outline: none;
                }
            }

            div {
                background: white;
                border: 1px solid #d6d6d6;
                border-radius: 27px;
                width: 54px;
                height: 54px;
                display: inline-block;
                position: relative;

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 25px;
                    color: #0d6efd;
                    // color: #d6d6d6;
                }
            }

            p {
                text-align: center;
                font-size: 14px;
                color: #464646;
            }

            &:after {
                content: "";
                width: 100%;
                height: 2px;
                background: #d6d6d6 0% 0% no-repeat padding-box;
                z-index: 0;
                position: absolute;
                top: 29px;
                left: 50%;
            }

            &:last-of-type {
                &:after {
                    content: none !important;
                }
            }
        }

        /* For current active node */
        button.activated {
            div {
                border: 1px solid #0d6efd;
            }

            div.red {
                border: 1px solid #dc3545;

                i {
                    color: #dc3545;
                }
            }
        }

        /* For successfully passed previous node */
        button.pass {
            div {
                background: #97e5ff 0% 0% no-repeat padding-box;
                border: 1px solid #0d6efd;

                i {
                    color: #0d6efd;
                }
            }

            // &:after {
            //   background: #fed13e 0% 0% no-repeat padding-box;
            // }
        }

        /* For disabling all next nodes by default if current node is not passed successfully*/
        button.disabled {
            cursor: not-allowed;

            div {
                i {
                    color: #d6d6d6;
                }
            }
        }

        li.line-pass {

            // button {
            //     div {
            //         background: #68bfdc 0% 0% no-repeat padding-box;
            //         border: 1px solid #68bfdc;
            //         i {
            //             // color: red;
            //         }
            //     }
            // }
            &:after {
                background: #fed13e 0% 0% no-repeat padding-box;
            }
        }
    }

    .step-progress {
        list-style-type: none;
        counter-reset: step;
    }

    .step-progress li {
        float: left;
        width: 50%;
        position: relative;
        text-align: center;
        // z-index: 999;

        &:before {
            content: counter(step);
            counter-increment: step;
            width: 30px;
            height: 30px;
            border: 2px solid #bebebe;
            display: block;
            margin: 0 auto 10px auto;
            border-radius: 50%;
            line-height: 27px;
            background-color: white;
            color: #bebebe;
            text-align: center;
            font-weight: bold;
            // z-index: 5;

        }

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #979797;
            top: 15px;
            left: -50%;
            z-index: -1;
        }

        &:first-child:after {
            content: none;
        }
    }

    li.active+li:after {
        background: #3aac5d;
    }

    li.active+li:before {
        border-color: #3aac5d;
        background: #3aac5d;
        color: white
    }
}

/*------------ Pagination CSS ------------*/
.pagination {
    font-family: 'Open Sans', sans-serif !important;
    .page-item {
        .page-link {
            padding: 4px 10px !important;
            font-size: 14px !important;
            height: 32px !important;
        }
        .page-link:focus {
            box-shadow: none !important;
        }
    }
}