.modal-content {
    box-shadow: 0 5px 15px rgba(220, 218, 218, 0.78);
    .modal-header {
        background-color: #363F65;
        background-image: url('https://compedica.com/wp-content/uploads/C-background-hero-blue.svg');
        background-repeat: no-repeat;
        background-size: contain;
        color: #FFF;
        button.close {
            span{
                color: #FFF;
            }
        }
        // &:active {
        //     border: 1px solid transparent !important;
            
        // }
    }
}