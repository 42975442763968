h1, h2, h3, h4, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
}

h6 {
    font-weight: 600 !important;
}

// a {

// }

// p {
        
// }

table {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 13px !important;
}