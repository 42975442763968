/* Common CSS for user authentication module */

.card-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;

    .auth-card {
        width: 37%;
        height: auto;
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 5px 10px 18px #888888;

        .auth-card-header {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            width: 100%;
            height: 85px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom: 2px solid #212b56;

            h4 {
                margin: 0;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
            }
        }

        .auth-form-container {
            padding: 20px 30px;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            
            h5 {
                font-weight: 600 !important;
            }

            p {
                margin-bottom: 30px;
            }

            .submit-button {
                font-family: 'Montserrat', sans-serif !important;
                span.p-button-label{
                    font-size: 15px;
                    font-weight: 400 !important;
                }
            }

            .submit-button:focus {
                box-shadow: none;
            }

            .forgot-password {
                text-align: left !important;
                font-size: 13px;

                a {
                    text-decoration: none;
                }
            }
        }
    }
}