/* Helper classes */
.w-20 {
    width: 20% !important;
}
// .w-25 {
//     width: 25% !important;
// }

// .w-50 {
//     width: 50% !important;
// }

// .w-100 {
//     width: 100% !important;
// }

.mb-10p {
    margin-bottom: 10px !important;
}

.mb-20p {
    margin-bottom: 20px !important;
}

.mb-30p {
    margin-bottom: 30px !important;
}

.mb-40p {
    margin-bottom: 40px !important;
}

.mb-50p {
    margin-bottom: 50px !important;
}



